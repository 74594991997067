import React from 'react'
import Layout from '../components/layout'
import StyledLink from '../components/styled-link'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>Sorry that link doesn't exist.</p>
    <StyledLink to={'/'} where={'Return to Home Page'} />
  </Layout>
)

export default NotFoundPage
